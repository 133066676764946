import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { graphql, Link } from 'gatsby';

export const query = graphql`
    query NotFoundPageQuery($exhibitionId: Int!) {
        arteiaBuild {
            exhibitions_by_pk(id: $exhibitionId) {
                title
                subtitle
                footer_text
                footer_phone
            }
        }
    }
`;

const NotFoundPageStyled = styled.div`
  min-height: calc(100vh - 450px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  h1 {
    text-align: center;
    text-transform: uppercase;
    font-weight: lighter;
    letter-spacing: 0.05rem;
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 300;
    padding-top: 68px;
  }
  
  h2 {
    text-align: center;
    font-size: 2.2rem;
    line-height: 2.6rem;
    font-weight: 500;
    word-wrap: break-word;
    padding-bottom: 60px;
    font-family: "Roboto";
  }
  
  a {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-decoration: none;
    color: #424242;
    font-family: "Roboto";
    font-weight: 500;
  }
`;

const NotFoundPage = ({
  data: {
      arteiaBuild: {
          exhibitions_by_pk,
      },
  },
  pageContext: {
      assetPath,
      language,
  }
}) => (
    <Layout>
        <Header assetsPath={assetPath} />
        <NotFoundPageStyled>
            <h1>404</h1>
            <h2>Page not found</h2>
            <Link to={`/${language}`}>
                {'Back to home page'}
            </Link>
        </NotFoundPageStyled>
        <Footer
            assetsPath={assetPath}
            exhibition={exhibitions_by_pk}
        />
    </Layout>
);

export default NotFoundPage;
